import request from '@/utils/request';

// 获取我的直播
export const liveList = params => request.post(`study/live/list`, params)

// 获取直播详情
export const liveDetail = params => request.get(`study/live/${params.taskId}/${params.detailId}/detail`)

// 观看直播
export const liveLearn = params => request.get(`study/live/${params.taskId}/${params.detailId}/log`)

// 企业微信获取直播状态
export const getWxLiveStatus = params => request.get(`study/live/${params.taskId}/${params.detailId}/wxdetail`)

// 钉钉获取直播状态
export const getDdLiveStatus = params => request.get(`study/live/${params.taskId}/${params.detailId}/dddetail`)

// 直播回放 列表
export const getPlayBackList = (id, did) => request.get(`study/live/${id}/${did}/playback/list`)
// 直播回放 详情
export const getPlayBackDetail = (id, did, docId) => request.get(`study/live/${id}/${did}/playback/${docId}/detail`)
// 直播回放 开始学习
export const startLearnByPlayBack = params => request.post(`study/live/playback/startlearn`, params)
// 直播回放 学习中
export const learningByPlayBack = params => request.post(`study/live/playback/learning`, params)
// 直播回放 计算播放次数
export const computedCount = (id, did) => request.get(`/study/live/${id}/${did}/playback/addcount`)